import {Login} from "../../features/login/Login";
import {useEffect} from "react";

const LoginPage = () => {
  useEffect(() => {
    localStorage.removeItem('token');
    document.title = 'Transfer Tool :: Login';
  }, []);
  return <Login />;
}

export default LoginPage;
