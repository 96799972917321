import React from 'react';
import './index.css';
import {Routing} from "../pages";

function App() {
  return (
    <Routing />
  );
}

export default App;
