import {Button, Form, Input} from "antd";
import React from "react";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const initialData = {
  name: 'New server name',
  address: 'https://',
  token: '',
};

export const ServerEditForm = ({ onUpdate, data, isCreate }) => {
  if (!isCreate && !data) {
    return <></>;
  }
  return <Form
    { ...layout }
    name="nest-messages"
    onFinish={onUpdate}
    style={{ maxWidth: 600 }}
    initialValues={isCreate ? initialData : data}
  >
    <Form.Item name={['name']} label="Server name" rules={[{ min: 1, required: true }]} >
      <Input />
    </Form.Item>
    <Form.Item name={['address']} label="Server API" rules={[{ min: 1, required: true }]}>
      <Input />
    </Form.Item>
    <Form.Item name={['token']} label="Server token" rules={[{ min: 1, required: true }]}>
      <Input />
    </Form.Item>
    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
      <Button type="primary" htmlType="submit">
        {!isCreate ? 'Update' : 'Add server'}
      </Button>
    </Form.Item>
  </Form>
};
