import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Button, notification, Space, Spin, Tabs} from "antd";
import {Actions} from "./Actions";
import { deleteAction, duplicateAction, fetchActions } from "./actionsAPI";
import {useNavigate} from "react-router";

export const ActionsList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  const loadActions = () => {
    fetchActions()
      .then(setData)
      .catch(error => {
        setData([]);
        notification.error({
          message: 'Failed to fetch data',
          description: error.message,
        })
      });
  };

  useEffect(() => {
    setData(null);
    loadActions();
  }, []);

  const onEdit = (item) => {
    navigate(`/actions/edit/${item._id}`);
  };

  const onDuplicate = (item) => {
    setLoading(true);
    duplicateAction(item)
      .then((action) => {
        setData([...data, action]);
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to duplicate action',
          description: error.message,
        })
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const onDelete = (item) => {
    setLoading(true);
    deleteAction(item._id)
      .then(() => {
        setData(data.filter(dataItem => dataItem._id !== item._id))
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to delete data',
          description: error.message,
        })
      })
      .finally(() => setLoading(false))
  };

  const onCreate = () => {
    navigate('/actions/new');
  };

  const items = [
    {
      key: '1',
      label: 'All',
      children: <Actions list={data} onDuplicate={onDuplicate} onEdit={onEdit} onDelete={onDelete} />
    }
  ];

  if (data) {
    const actionsByChannel = _.groupBy(data, (item) => `${item.channel}`.toUpperCase());
    Object.entries(actionsByChannel).forEach(([channel, list], index) => {
      items.push({
        key: `${index + 2}`,
        label: channel,
        children: <Actions list={list} onDuplicate={onDuplicate} onEdit={onEdit} onDelete={onDelete} />
      })
    });
  }


  return <Spin spinning={loading} tip="loading...">
    <Space wrap>
      <Button type="primary" onClick={onCreate} >Create</Button>
    </Space>
    <Tabs onChange={setActiveTab} activeKey={activeTab} items={items} />

  </Spin>
}
