import _ from "lodash";
import {Layout, Menu, Select, theme} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import { UserOutlined } from '@ant-design/icons';
import {createElement, useEffect, useState} from "react";
import Sider from "antd/es/layout/Sider";
import {Outlet, useLocation, useNavigate} from "react-router";
import {connect} from "react-redux";
import {getServersAction, selectServerAction} from "../../features/servers/actions/server-actions";
import {Option} from "antd/es/mentions";

const TopMenuItems = [
  {
    key: '6',
    label: 'Servers setup',
    path: '/servers'
  },
  {
    key: '2',
    label: 'General',
    path: '/general',
  },
  {
    key: '3',
    label: 'Actions',
    path: '/actions',
  },
  {
    key: '5',
    label: 'Backups',
    path: '/backups'
  },
];
const TopMenuItemsByKey = _.keyBy(TopMenuItems, 'key');

const LeftMenuItems = () => {
  return [
    {
      key: '2',
      icon: createElement(UserOutlined),
      label: 'Servers',
      children: [
        {
          key: '6',
          label: 'Servers setup',
          path: '/servers'
        },
      ]
    },
    {
      key: '1',
      icon: createElement(UserOutlined),
      label: 'Transfer Tool',
      children: [
        {
          key: '2',
          label: 'General',
          path: '/general',
        },
        {
          key: '3',
          label: 'Actions',
          path: '/actions',
        },
        {
          key: '5',
          label: 'Backups',
          path: '/backups'
        },
      ]
    }
  ];
};

const getCurrentPageKey = (location) => {
  const currentPage = [...TopMenuItems]
    .reverse()
    .find(_item => location.pathname.startsWith(_item.path))

  return currentPage ? currentPage.key : '1';
}

const renderSelect = (servers, selectedServer, onSelect) => {
  const serverId = selectedServer?.id || 0;

  const onSelectServer = (newId) => {
    const newServer = servers.find(server => Number(server.id) === Number(newId));
    if (newServer) {
      onSelect(newServer);
    }
  }

  return <Select style={{ width: '100%' }} onSelect={onSelectServer} value={serverId}>
    { (servers || []).map(server => <Option value={server.id}>{server.name}</Option>) }
  </Select>
};

const RootPage = ({ getServersAction, servers, selectServerAction, selectedServer }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Transfer Tool';
  }, []);
  const location = useLocation();
  const [key, setKey] = useState(getCurrentPageKey(location));

  useEffect(() => {
    setKey(getCurrentPageKey(location));

    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [location, navigate]);

  useEffect(() => {
    getServersAction();
  }, []);

  const menuClick = ({ key }) => {
    if (TopMenuItemsByKey[key]) {
      navigate(TopMenuItemsByKey[key].path);
    }
  };

  const onChangeServer = (item) => {
    selectServerAction(item);
    navigate('/servers');
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return <Layout>
    <Header className="header">
      <div className="logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[key]}
        items={TopMenuItems}
        onClick={menuClick}
      />
    </Header>
    <Content style={{ padding: '0 50px' }}>
      <Layout style={{ padding: '24px 0', background: colorBgContainer }}>
        <Sider style={{ background: colorBgContainer, height: '100%' }} width={200}>
          <div style={{ width: '100%', 'padding-left': '5px', 'padding-right': '5px', 'padding-bottom': '10px' }}>
            <strong>Selected server</strong>
          </div>
          <div style={{ width: '100%', 'padding-left': '5px', 'padding-right': '5px', 'padding-bottom': '10px' }}>
            {renderSelect(servers, selectedServer, onChangeServer)}
          </div>
          <Menu
            mode="inline"
            selectedKeys={[key]}
            defaultOpenKeys={['1', '2']}
            style={{ height: '100%' }}
            items={LeftMenuItems()}
            onClick={menuClick}
          />
        </Sider>
        <Content style={{ padding: '0 24px', minHeight: 280 }}>
          <Outlet />
        </Content>
      </Layout>
    </Content>
    <Footer style={{ textAlign: 'center' }}>Copyright © Mediatool Sp. z.o.o.</Footer>
  </Layout>
};

const mapStateToProps = state => ({
  servers: state?.servers?.serverList || [],
  selectedServer: state?.servers?.selectedServer || [],
})

const mapDispatchToProps = dispatch => ({
  getServersAction: () => dispatch(getServersAction),
  selectServerAction: (server) => dispatch(selectServerAction(server)),
});

const Root = connect(
  mapStateToProps,
  mapDispatchToProps
)(RootPage);

export default Root;
