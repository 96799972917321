import { modelConvertToNum, modelConvertToStr } from "../constants/constants";

/**
 * The S3Settings model module.
 * @module model/S3Settings
 * @version 1.0.0
 */
class S3Settings {
  /**
   * Constructs a new <code>S3Settings</code>.
   * @alias module:model/S3Settings
   */
  constructor() {
    /**
     * @member {string} host
     * @default 's3.localhost'
     */
    this.host = 's3.localhost';

    /**
     * @member {number} port
     * @default 9000
     */
    this.port = 9000;

    /**
     * @member {string} access_key
     * @default ''
     */
    this.access_key = 'access';

    /**
     * @member {string} secret_key
     * @default ''
     */
    this.secret_key = 'secret';

    /**
     * @member {string} region
     * @default ''
     */
    this.region = 'eu-central-1';

    /**
     * @member {string} path
     * @default ''
     */
    this.path = '/';
  }

  /**
   * Constructs a <code>S3Settings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/S3Settings} obj Optional instance to populate.
   * @return {S3Settings} The populated <code>S3Settings</code> instance.
   */
  static constructFromObject(data, obj = null) {
    obj = obj || new S3Settings();

    if (data) {
      if (data.hasOwnProperty('host')) {
        obj.host = modelConvertToStr(data.host);
      }

      if (data.hasOwnProperty('port')) {
        obj.port = modelConvertToNum(data.port);
      }

      if (data.hasOwnProperty('access_key')) {
        obj.access_key = modelConvertToStr(data.access_key);
      }

      if (data.hasOwnProperty('secret_key')) {
        obj.secret_key = modelConvertToStr(data.secret_key);
      }

      if (data.hasOwnProperty('region')) {
        obj.region = modelConvertToStr(data.region);
      }

      if (data.hasOwnProperty('path')) {
        obj.path = modelConvertToStr(data.path);
      }
    }

    return obj;
  }
}

export default S3Settings;
