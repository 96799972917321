import {ServerEditForm} from "./ServerEditForm";
import {useState} from "react";
import {notification, Spin} from "antd";
import {createServer} from "./serversAPI";
import {useNavigate} from "react-router";

export const ServerAdd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onUpdate = (data) => {
    setLoading(true);
    createServer(data)
      .then(() => navigate('/servers'))
      .catch(() => {
        notification.error({
          message: 'Failed to create server',
        })
      })
      .finally(() => setLoading(false));
  };

  return <Spin spinning={loading}>
    <ServerEditForm data={null} isCreate={true} onUpdate={onUpdate} />
  </Spin>
};
