import React from 'react';
import {List, Modal} from "antd";

const getItemDescription = (item) => {
  const channelGroup = item.channel || '';
  const channelNameList = (item.destinations || []).map(record => record.channelName).join(', ');
  const sourcePath = item.source.path || '';
  const destinationPaths = (item.destinations || []).map(record => record.path).join(', ');

  return {
    channelInfo: `Channels: ${channelNameList} [${channelGroup}]`,
    pathInfo: `Source path: ${sourcePath} | Destination paths: ${destinationPaths}`,
  }
};

const onDeleteAlert = (item, onDelete) => {
  const {channelInfo, pathInfo} = getItemDescription(item);

  Modal.confirm({
    title: 'Are you sure delete this action?',
    content: <>{channelInfo}<br/>{pathInfo}</>,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      onDelete(item);
    },
  });
};

export const Actions = ({list, onDelete, onEdit, onDuplicate}) => {
  return <List
    className="demo-loadmore-list"
    loading={!list}
    itemLayout="horizontal"
    dataSource={list || []}
    renderItem={(item) => {
      const channelGroup = item.channel || '';
      const channelNameList = (item.destinations || []).map(record => record.channelName).join(', ');
      const sourcePath = item.source.path || '';
      const destinationPaths = (item.destinations || []).map(record => record.path).join(', ');
      return <List.Item
        actions={[<a onClick={() => onDuplicate(item) } key="list-loadmore-edit">duplicate</a>, <a onClick={() => onEdit(item) } key="list-loadmore-edit">edit</a>, <a onClick={() => onDeleteAlert(item, onDelete) } key="list-loadmore-more">delete</a>]}
      >
        <List.Item.Meta
          title={`Channels: ${channelNameList} [${channelGroup}]`}
          description={`Source path: ${sourcePath} | Destination paths: ${destinationPaths}`}
        />
      </List.Item>
    }}
  />;
}
