const initialState = {
  serverList: [],
};

const serversReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SERVER_LIST':
      return {
        ...state,
        serverList: action.payload,
      }
    case 'SELECT_SERVER':
      return {
        ...state,
        selectedServer: action.payload,
      }
    default:
      return state
  }
}

export default serversReducer
