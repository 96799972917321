import {
  CloneMode,
  CloneModeSet,
  FileExtensionType,
  FileExtensionTypeSet,
  modelConvertToStr
} from "../constants/constants";
import FtpSettings from "./ftp-settings";
import DestinationFtpSettings from "./destination-ftp-settings";
import _ from "lodash";

/**
 * The ActionConfig model module.
 * @module model/ActionConfig
 * @version 1.0.0
 */
class ActionConfig {
  /**
   * Constructs a new <code>ActionConfig</code>.
   * @alias module:model/ActionConfig
   */
  constructor() {
    ActionConfig.readOnlyFields = [
      '_id',
      'apiUrlStatus',
    ]

    /**
     * @member {string} _id
     * @default null
     */
    this._id = null;

    /**
     * @member {CloneMode} mode
     * @default 'copy'
     */
    this.mode = CloneMode.copy;

    /**
     * @member {string} apiKey
     * @default ''
     */
    this.apiKey = '';

    /**
     * @member {string} channel
     * @default ''
     */
    this.channel = '';

    /**
     * @member {string} interval
     * @default '15m'
     */
    this.interval = '15m';

    /**
     * @member {DestinationFtpSettings[]} destinations
     * @default []
     */
    this.destinations = [new DestinationFtpSettings()];

    /**
     * @member {FtpSettings} source
     * @default FtpSettings
     */
    this.source = new FtpSettings();

    /**
     * @member {FileExtensionType} extensionType
     * @default all
     */
    this.extensionType = FileExtensionType.all;

    this.apiUrlStatus = {};
  }

  /**
   * Constructs a <code>ActionConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ActionConfig} obj Optional instance to populate.
   * @param isUpdate
   * @return {ActionConfig} The populated <code>ActionConfig</code> instance.
   */
  static constructFromObject(data, obj = null, isUpdate = false) {
    obj = obj || new ActionConfig();

    if (data) {
      if (data.hasOwnProperty('apiUrlStatus')) {
        obj.apiUrlStatus = _.cloneDeep(data.apiUrlStatus);
      }

      if (data.hasOwnProperty('mode') && CloneModeSet.has(data.mode)) {
        obj.mode = modelConvertToStr(data.mode);
      }

      if (data.hasOwnProperty('extensionType') && FileExtensionTypeSet.has(data.extensionType)) {
        obj.extensionType = modelConvertToStr(data.extensionType);
      }

      if (data.hasOwnProperty('apiKey')) {
        obj.apiKey = modelConvertToStr(data.apiKey);
      }

      if (data.hasOwnProperty('_id')) {
        obj._id = modelConvertToStr(data._id);
      }

      if (data.hasOwnProperty('channel')) {
        obj.channel = modelConvertToStr(data.channel);
      }

      if (data.hasOwnProperty('interval')) {
        obj.interval = modelConvertToStr(data.interval);
      }

      if (data.hasOwnProperty('destinations') && Array.isArray(data.destinations)) {
        obj.destinations = data.destinations.map(item => DestinationFtpSettings.constructFromObject(item, null, isUpdate));
      }

      if (data.hasOwnProperty('source')) {
        obj.source = FtpSettings.constructFromObject(data.source, null, isUpdate);
      }
    }

    if (isUpdate) {
      return _.omit(obj, ActionConfig.readOnlyFields || [])
    }

    return obj;
  }
}

export default ActionConfig;
