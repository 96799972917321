import {fetchApi, fetchServerApi} from "../fetch-api";

export const fetchBackups = async () => {
  const result = await fetchServerApi().get('/backups');
  return result.data;
}

export const applyBackup = async (backupId) => {
  await fetchServerApi().post('/backups', {
    fileId: backupId,
  });
}
