import {fetchApi, setToken} from "../fetch-api";

export const loginAction = async (username, password) => {
  const result = await fetchApi().post('/auth/login', {
    username,
    password,
  });
  const { token } = result.data;
  localStorage.setItem('token', token);
  setToken(token);
}

export const updateGeneral = async (data) => {
  const result = await fetchApi().put('/settings/global', data);
  return result.data;
}
